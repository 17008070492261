window.debounce = function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
          args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

window.menuSetActiveItem = function menuSetActiveItem(item) {
  const navigator = document.getElementById("Nav-Indicator");
  navigator.style.top = `${item * 81 + 3}px`;

  // Add active to li item
  const liItems = document.querySelectorAll("li.Nav-Item");
  liItems.forEach(function (element) {
    if (element.dataset.idx == item) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  });
};

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Fade in element based on viewport visibility
   * */
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  function observerCallback(entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        // fade in observed elements that are in view
        entry.target.classList.replace("fadeOut", "fadeIn");
      } else {
        // fade out observed elements that are not in view
        entry.target.classList.replace("fadeIn", "fadeOut");
      }
    });
  }

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  const fadeElms = document.querySelectorAll(".fade");
  fadeElms.forEach(function (el) {
    el.classList.add("fadeOut");
    observer.observe(el);
  });
});

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Fade in images on load
   * */
  const images = document.getElementsByTagName("img");

  for (let image of images) {
    image.classList.add("loading");
    // Watch image loading
    let img = new window.Image();
    img.src = image.src;
    img.onload = function () {
      image.classList.replace("loading", "loaded");
    };
  }
});

/**
 * Add caption to fsLightbox images
 */
document.addEventListener('DOMContentLoaded', function() {
  const fsLightboxLinks = document.querySelectorAll('a[data-fslightbox]');

  if (fsLightboxLinks.length > 0) {
    // Create a map of links with data-fslightbox attribute for captions
    // Key: a.href, Value: a.data-caption
    const fsLightboxCaptions = Array.from(fsLightboxLinks).reduce((acc, link) => {
      acc[link.href] = link.dataset.caption;
      return acc;
    }, {});

    const targetNode = document.body;
    const config = { childList: true };

    const callback = function(mutationsList) {
      mutationsList.forEach(mutation => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(addedNode => {
            if (addedNode.classList && addedNode.classList.contains("fslightbox-container")) {
              console.log("Lightbox added");

              // Query images within the newly added lightbox container
              const images = addedNode.querySelectorAll("img.fslightbox-source");

              images.forEach(image => {
                const caption = fsLightboxCaptions[image.src];

                // Check if caption is not already created
                if (!image.parentNode.querySelector(".fs-lightbox-caption") && caption) {
                  const captionEl = document.createElement("div");
                  captionEl.classList.add("fs-lightbox-caption", "text-center", "p-4");
                  captionEl.innerHTML = caption;
                  image.parentNode.appendChild(captionEl);
                }
              });
            }
          });
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }
});
